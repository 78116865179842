import request from '@/utils/request'

export function getByList() {
    return request({
        url: '/net/getList',
        method: 'get'
    })
}

export function getByIds(title) {
    return request({
        url: '/net/getByIds?title=' + title,
        method: 'get'
    })
}

export function getList(pageNum,pageSize) {
    return request({
        url: '/net/list?pageNum=' + pageNum + '&pageSize=' + pageSize, 
        method: 'get'
    })
}

export function addData(data) {
    return request({
        url: '/net/save', 
        method: 'post',
        data
    })
}

export function editData(id,data) {
    return request({
        url: '/net/edit?id=' + id, 
        method: 'post',
        data
    })
}


export function delData(id) {
    return request({
        url: '/net/delete?id=' + id, 
        method: 'post',
    })
}
