<template>
  <div>
    <el-dialog
      :title="con.id ? '编辑净值数据' : '添加净值数据'"
      :visible.sync="dialogVisible"
      :before-close="outClose"
      width="650px"
    >
      <div class="from">
        <div class="list-data">
          <!-- <h3>基本信息</h3> -->
          <div class="list-dialog">
            <div class="list" style="width: 49%">
              <p style="margin-bottom: 10px">
                产品名称
                <span style="color: red; margin-left: 0px; font-weight: 500"
                  >*</span
                >
              </p>

              <el-input
                  size="medium"
                  v-model="con.title"
                  placeholder="请输入内容"
                  disabled
                ></el-input>
            </div>

            <div class="list" style="width: 49%">
              <p style="margin-bottom: 10px">
                产品编号
                <span style="color: red; margin-left: 0px; font-weight: 500"
                  >*</span
                >
              </p>
              <el-input
                size="medium"
                v-model="con.code"
                placeholder="请输入内容"
              ></el-input>
            </div>
          </div>

          <div class="list-dialog">
            <div class="list" style="width: 49%">
              <p style="margin-bottom: 10px">
                单位净值
                <span style="color: red; margin-left: 0px; font-weight: 500"
                  >*</span
                >
              </p>
              <el-input
                size="medium"
                v-model="con.unit"
                placeholder="请输入内容"
              ></el-input>
            </div>

            <div class="list" style="width: 49%">
              <p style="margin-bottom: 10px">
                累计净值
                <span style="color: red; margin-left: 0px; font-weight: 500"
                  >*</span
                >
              </p>
              <el-input
                size="medium"
                v-model="con.grand"
                placeholder="请输入内容"
              ></el-input>
            </div>
          </div>

          <div class="list-dialog">

            <div class="list" style="width: 49%">
              <p style="margin-bottom: 10px">
                净值日期
                <span style="color: red; margin-left: 0px; font-weight: 500"
                  >*</span
                >
              </p>
              <el-date-picker
                v-model="con.time"
                value-format="yyyy-MM-dd"
                placeholder="选择成立时间"
              >
              </el-date-picker>
            </div>
          </div>
        </div>
      </div>

      <template #footer>
        <div type="flex" slot="footer" class="dialog-footer">
          <el-button type="info" @click="outClose()">取 消</el-button>
          <el-button type="primary" @click="submit()">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
      
      <script>
// import { getList } from "@/api/mysql";
import { addProduct, editData } from "@/api/net";
import { getByList } from "@/api/net";
export default {
  props: {
    con: {
      type: Object,
      default: () => {},
    },
    mapList: {
      type: Array,
      default: () => [],
    },
    groupList: {
      type: Array,
      default: () => [],
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      total: 0,
      logoHide: false,
      options: [],
    };
  },
  watch: {
    dialogVisible(data) {
      if (!data) {
        for (let key in this.con) {
          this.con[key] = "";
        }
      } else {
        getByList().then((res) => {
          console.log(res);
          this.options = res;
        });
      }
    },
  },
  created() {
    // this.getList();
  },
  methods: {
    logoUploadSuccess(res) {
      this.$refs.logoImage.clearFiles();
      if (res.code == 200) {
        this.con.imgs = "http://api.zhonghuan.com:8888/" + res.data;
      } else {
        this.$message.error(res.msg);
      }
      this.$forceUpdate();
    },

    logoRemove() {
      this.con.imgs = "";
      this.$forceUpdate();
    },

    submit() {
      if (this.con.id) {
        this.edit();
      } else {
        this.add();
      }
    },
    add() {
      addProduct(this.con).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.$emit("outClose");
        }
      });
    },
    edit() {
        editData(this.con.id, this.con).then((res) => {
        if (res.code == 200) {
          this.$message.success("编辑成功");
          this.$emit("outClose");
        }
      });
    },
    outClose() {
      this.$emit("outClose");
    },
  },
};
</script>
      
      <style lang="less" scoped>
/deep/ .logo_hide .el-upload--picture-card {
  display: none !important;
}
/deep/ .el-checkbox.is-bordered.el-checkbox--medium {
  margin-right: 0px;
}
/deep/ .el-dialog__body {
  padding: 0px 20px;
}
/deep/ .el-dialog__header {
  padding: 10px 10px 10px;
  border-bottom: 1px solid #f5f6f7;
}
/deep/ .el-dialog__headerbtn {
  top: 15px;
}

/deep/ .el-upload--picture-card {
  width: 110px !important;
  height: 110px !important;
  line-height: 110px !important;
  margin-left: 5;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 110px !important;
  height: 110px !important;
  line-height: 110px !important;
}

/deep/ .item .list .el-input[data-v-3f61525b],
.item .list .el-textarea[data-v-3f61525b] {
  width: 300px !important;
  margin-right: 20px;
}

/deep/ .avatar {
  width: 100%;
  height: 100%;
  display: block;
}

/* 弹出框居中显示 */
/deep/ .el-dialog {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0px !important;
}
/* 弹出框超出部分滑动 */
/deep/ .el-dialog__body {
  overflow: hidden;
  overflow-y: auto;
}
/deep/ .el-radio {
  margin-right: 0px;
}

/deep/ .el-select .el-input {
  width: 100%;
}
.list-data {
  width: 100%;
  margin-top: 20px;
  h3 {
    width: 100%;
    height: 45px;
    line-height: 45px;
    background: #f57a3d;
    color: #fff;
    border-radius: 3px;
    text-indent: 10px;
    font-size: 15px;
    margin-bottom: 20px;
  }
  .list-dialog {
    width: 95%;
    margin: auto;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    .list {
      width: 100%;
      margin-bottom: 5px;
      .el-input {
        width: 100%;
      }
      .el-date-picker {
        width: 100%;
      }
    }
  }
}

.up_list {
  width: 95%;
  display: flex;
  margin: auto;
  margin-bottom: 20px;
  p {
    display: flex;
    margin-right: 20px;
    span {
      width: 10px;
      font-size: 15px;
      color: #000;
      opacity: 0.9;
      display: block;
    }
  }
}
.itemIms {
  width: auto;
  display: flex;
  .img_list {
    width: 110px;
    height: 110px;
    margin-right: 10px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
    p {
      position: absolute;
      width: 100%;
      height: 100%;
      line-height: 110px;
      background: rgba(0, 0, 0, 0.8);
      top: 0;
      color: #fff;
      text-align: center;
      cursor: pointer;
      opacity: 0;
      transition: 0.3s;
      border-radius: 5px;
      text-align: center;
      display: block;
      font-size: 20px;
    }
  }
  .img_list:hover p {
    opacity: 1;
  }
}
</style>