<template>
  <div>
    <div class="nav">
      <h1>基金产品/净值数据管理</h1>
    </div>
    <div class="search">
      <div class="left">
        <div class="box">
          <el-input
            placeholder="请输入内容"
            style="width: 300px"
            v-model="con.merchantName"
            clearable
            class="input-with-select"
          >
            <template slot="prepend">产品名称</template>
          </el-input>
        </div>
      </div>
      <div class="right">
        <!-- <el-button @click="saveData()" type="file">导入数据</el-button> -->
        <el-upload
          class="upload"
          action="#"
          :show-file-list="false"
          :on-change="handleExcel"
          accept="'.xlsx','.xls'"
          :auto-upload="false"
          :headers="headers"
        >
          <el-button type="primary">导入数据</el-button>
        </el-upload>

        <!-- <el-button type="primary" @click="saveData()">新增数据</el-button> -->
      </div>
    </div>

    <div class="result">
      <span></span>
      <p>查询结果</p>
    </div>

    <div class="table">
      <el-table
        :data="tableData"
        style="width: 100%"
        header-cell-style="background: #f5f7fa;"
      >
        <el-table-column
          align="center"
          prop="title"
          :show-overflow-tooltip="true"
          label="产品名称"
          fixed="left"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="code"
          :show-overflow-tooltip="true"
          label="产品编号"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="unit"
          :show-overflow-tooltip="true"
          label="单位净值"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="grand"
          width="170"
          label="累计净值"
        ></el-table-column>

        <el-table-column
          align="center"
          prop="time"
          :show-overflow-tooltip="true"
          label="净值日期"
        ></el-table-column>
        <!-- <el-table-column align="center" prop="sortOrder" label="排序" width="100"></el-table-column> -->
        <el-table-column
          align="center"
          prop="address"
          label="操作"
          fixed="right"
        >
          <template slot-scope="scope">
            <div class="flex">
              <el-tooltip
                class="item"
                @click.native="editNews(scope.row)"
                effect="dark"
                content="编辑产品"
                placement="top"
              >
                <p class="table-btn">
                  <i class="el-icon-edit"></i>
                </p>
              </el-tooltip>

              <el-tooltip
                class="item"
                @click.native="delList(scope.row.id)"
                effect="dark"
                content="删除产品"
                placement="top"
              >
                <p class="table-btn">
                  <i class="el-icon-delete-solid"></i>
                </p>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageChange"
          :page-sizes="[10, 50, 100, 150, 200]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>

    <operationNet
      :dialogVisible="dialogVisible"
      :con="con"
      @outClose="dialogVisible = false"
    />
  </div>
</template>
    
<script>
import operationNet from "./components/operationNet.vue";
import { getList, delData,addData } from "@/api/net";
const xlsx = require("xlsx");
export default {
  components: {
    operationNet,
  },
  inject: ["reload"],
  data() {
    return {
      total: 0,
      pageNum: 1,
      pageSize: 10,
      item: [],
      tableData: [],
      classifyList: [],
      dialogVisible: false,
      netDialogVisible: false,
      con: {},
    };
  },
  created() {
    this.getList();
  },
  watch: {
    dialogVisible(data) {
      if (!data) {
        this.getList();
      }
    },
  },
  methods: {
    openNet() {
      this.netDialogVisible = true;
    },
    saveData() {
      this.dialogVisible = true;
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.getList();
    },
    pageChange(e) {
      this.pageNum = e;
      this.getList();
    },

    editNews(data) {
      this.con = JSON.parse(JSON.stringify(data));
      this.dialogVisible = true;
    },
    delList(id) {
      let that = this;
      this.$confirm("您正在删除净值数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delData(id).then((res) => {
            console.log(res);
            that.dialogVisible = false;
            that.$message.success("删除成功");
            that.reload();
          });
        })
        .catch(() => {
          that.$message.info("删除失败");
        });
    },
    getList() {
      getList(this.pageNum,this.pageSize).then((res) => {
        console.log(res)
        this.tableData = res.data.list;
        this.total = res.data.total
      });
    },

    async handleExcel(file) {
      let dataBinary = await new Promise((resolve) => {
        let reader = new FileReader();
        reader.readAsBinaryString(file.raw);
        reader.onload = (ev) => {
          resolve(ev.target.result);
        };
      });

      let workBook = xlsx.read(dataBinary, { type: "binary", cellDates: true });
      let firstWorkSheet = workBook.Sheets[workBook.SheetNames[0]];
      const data = xlsx.utils.sheet_to_json(firstWorkSheet);
      let con = [];
      data.forEach(function (list) {
        con.push({
          title: list.产品名称,
          code: list.产品编号,
          time: list.净值日期,
          unit: list.单位净值,
          grand: list.累计净值,
        });
      });
      console.log(con);
      addData(con).then(res => {
        console.log(res)
      })
      // this.queding(con);
      // console.log("读取所有excel数据", data);
    },
    getHeaderRow(sheet) {
      const headers = []; // 定义数组，用于存放解析好的数据
      const range = xlsx.utils.decode_range(sheet["!ref"]); // 读取sheet的单元格数据
      let C;
      const R = range.s.r;
      /* start in the first row */
      for (C = range.s.c; C <= range.e.c; ++C) {
        /* walk every column in the range */
        const cell = sheet[xlsx.utils.encode_cell({ c: C, r: R })];
        /* find the cell in the first row */
        let hdr = "UNKNOWN " + C; // <-- replace with your desired default
        if (cell && cell.t) hdr = xlsx.utils.format_cell(cell);
        headers.push(hdr);
      }
      return headers; // 经过上方一波操作遍历，得到最终的第一行头数据
    },
  },
};
</script>
    
    <style lang="less" scoped>
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.search {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  .left {
    width: 60%;
    display: flex;
    align-items: flex-end;
    .box {
      display: flex;
      /deep/ .el-input {
        margin-right: 20px;
        //   margin-left: 20px;
      }
    }
    /deep/ .el-select {
      width: 250px;
      margin-right: 20px;
    }
    .el-button {
      margin-left: 20px;
    }
    .input-with-select {
      width: 600px;
    }
  }
  .right {
    // width: 40%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    .box {
      display: flex;
      align-items: center;
    }
  }
}

.table {
  margin-top: 20px;
  .shop {
    display: flex;
    align-items: center;
    img {
      width: 40px;
      height: 40px;
      margin-right: 5px;
    }
    p {
      position: relative;
    }
  }
}
</style>